import { Component, OnInit } from '@angular/core';
import {MultiLanguageService} from "../shared/translate/multiLanguageService";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  prefixName = 'home';

  constructor(
      public multiLanguageService: MultiLanguageService,
      // public translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
    // this.translateService.get(prefixName + '.title' ).subscribe((res: string) => {
    //   console.log(res);
    // });
  }
}
