import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-blank',
    templateUrl: './blank.component.html',
    styleUrls: ['./blank.component.scss'],
})
export class AppBlankComponent {
    dir = 'ltr';
    green: boolean;
    blue: boolean;
    dark: boolean;
    minisidebar: boolean;
    boxed: boolean;
    danger: boolean;
    showHide: boolean;

    constructor(public router : Router) {}

}
