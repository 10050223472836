// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,


    base_href: '',

    auth_cookie: 'dws',
    auth_token: 'dws',

    web_base_url: 'https://dws-developers.vidiva-dev.com',
    api_base_url: 'https://dws-developers.vidiva-dev.com/api',

    accounts_web_base_url: 'https://dws-accounts.vidiva-dev.com',
    accounts_api_base_url: 'https://dws-accounts.vidiva-dev.com/api',

    login_url: 'https://dws-accounts.vidiva-dev.com/authentication/login',

    google_captcha_site_key: '6Ldgc6YUAAAAADF30XlbeBctDJ1uT74Bad40QRRD',
    google_captcha_language: 'en', // USE VIETNAMESE


};
