import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }
}
