import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {filter, flatMap, map, switchMap} from 'rxjs/operators';
import {IResponse} from '../interfaces/response';
import {Observable, of} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RoutesRecognized} from "@angular/router";

export interface BadgeItem {
    type: string;
    value: string;
    roles?: string[];
    ignore_project_id?: boolean;
}

export interface Separator {
    name: string;
    type?: string;
    roles?: string[];
    ignore_project_id?: boolean;
}

export interface SubChildren {
    state: string;
    name: string;
    type?: string;
    roles?: string[];
    ignore_project_id?: boolean;
}

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
    roles?: string[];
    ignore_project_id?: boolean;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    separator?: Separator[];
    children?: ChildrenItems[];
    roles?: string[];
    ignore_project_id?: boolean;
}

const MENUITEMS = [
    {
        state: '',
        name: 'ADMIN',
        type: 'separator',
        icon: 'fiber_manual_record',
        roles: ['ADMIN', 'IS_SUPERUSER'],
    },
    {
        state: 'sysadmin/client',
        name: 'Client',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
        ignore_project_id: true
    },
    {
        state: 'sysadmin/team',
        name: 'Team',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
        ignore_project_id: true
    },
    {
        state: 'sysadmin/project',
        name: 'Project',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
        ignore_project_id: true
    },
    {
        state: '',
        name: 'SERVICES',
        type: 'separator',
        icon: 'fiber_manual_record',
        roles: ['ADMIN', 'IS_SUPERUSER'],
    },
    {
        state: 'services/docs',
        name: 'Docs',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },
    {
        state: 'services/task',
        name: 'Task',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },
    {
        state: 'services/email',
        name: 'Email',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },
    {
        state: 'services/sms',
        name: 'SMS',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },
    {
        state: 'services/secure-storage',
        name: 'Secure storage',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },
    {
        state: 'services/dcm',
        name: 'DCM',
        type: 'link',
        roles: ['ADMIN', 'IS_SUPERUSER'],
        icon: 'fiber_manual_record',
    },


    // {
    //     state: 'sysadmin/iam',
    //     name: 'IAM',
    //     type: 'sub',
    //     icon: 'fiber_manual_record',
    //     roles: ['ADMIN', 'IS_SUPERUSER'],
    //     children: [
    //         {state: 'backend-user', name: 'Backend users', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    //         {state: 'op-member', name: 'Operator members', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    //         {state: 'merchant-member', name: 'Merchant members', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    //         {state: 'agent-member', name: 'Agent members', type: 'link', roles: ['ADMIN', '*']},
    //         {state: 'government-member', name: 'Government members', type: 'link', roles: ['ADMIN', 'IS_SUPERUSER']},
    //         // {state: 'op-user', name: 'Operator users', type: 'link', roles: ['ADMIN']},
    //         // {state: 'agent-user', name: 'Agent users', type: 'link', roles: ['ADMIN']},
    //         // {state: 'merchant-user', name: 'Merchant users', type: 'link', roles: ['ADMIN']},
    //         // {state: 'government-user', name: 'Government users', type: 'link', roles: ['ADMIN']},
    //     ]
    // },
    // {
    //     state: 'sysadmin/merchant',
    //     name: 'Merchant',
    //     type: 'sub',
    //     icon: 'fiber_manual_record',
    //     roles: ['ADMIN'],
    //     children:[
    //         {state:'manage', name:'Manage', type:'link',roles:['ADMIN']},
    //     ]
    // },
    // {
    //     state: 'sysadmin/agent',
    //     name: 'Agent',
    //     type: 'sub',
    //     icon: 'fiber_manual_record',
    //     roles: ['ADMIN'],
    //     children:[
    //         {state:'manage', name:'Manage', type:'link',roles:['ADMIN']},
    //     ]
    // },
    // {
    //     state: 'wallet',
    //     name: 'Wallets',
    //     type: 'link',
    //     roles: ['ADMIN'],
    //     icon: 'fiber_manual_record',
    // },
    // {
    //     state: 'bank',
    //     name: 'Banks',
    //     type: 'link',
    //     roles: ['ADMIN'],
    //     icon: 'fiber_manual_record',
    // },

];

@Injectable()
export class MenuItems {
    private static _generateUrl() {
        return environment.api_base_url + '/me/roles';
    }

    private static _generateParams() {
        return new HttpParams();
    }

    private static _hasRole(userRoles, menuRoles) {
        if (userRoles && userRoles.indexOf('*') >= 0) {
            return true;
        }
        if (menuRoles && menuRoles.indexOf('*') >= 0) {
            return true;
        }
        if (!menuRoles || !userRoles) {
            return false;
        }

        return userRoles.filter(item => menuRoles.indexOf(item) >= 0).length > 0
    }

    public menuItems = [];

    constructor(
        private httpClient: HttpClient,
        private router: Router,
    ) {}

    public refreshMenuItem() {
        const requestUrl = MenuItems._generateUrl();
        const requestParams: HttpParams = MenuItems._generateParams();
        this.menuItems=[];

        return this.httpClient.get<any>(requestUrl,
            {params: requestParams}
        ).pipe(
            flatMap((res: IResponse) => {
                let roles: string[] = res.data.roles;
                if (res.data.is_superuser) roles.push('IS_SUPERUSER');
                const self = this;
                MENUITEMS.forEach(function (menu: Menu, index, array) {
                    const _menu = menu;
                    if (menu.type === 'sub') {
                        const _children = [];
                        const children = menu.children;
                        children.forEach(function (child: ChildrenItems, _index, _array) {
                            if (MenuItems._hasRole(roles, child.roles)) {
                                _children.push(child);
                            }
                        });
                        _menu.children = _children;
                    }
                    if (MenuItems._hasRole(roles, menu.roles)) {
                        self.menuItems.push(_menu);
                    }
                });
                return of (null);
            })
        )
    }

    getMenuItem(): Menu[] {
        return this.menuItems;
    }
}
