import {Injectable, Optional} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AjaxValidatorService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  ajaxValidator(
    form: FormGroup,
    url: string,
  ) {
    const requestUrl = environment.api_base_url + url;
    const data = form.getRawValue();

    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
    ;

    return this.httpClient.post<any>(requestUrl,
      data, {
        headers: headers
      }
    ).pipe(
      map(res => {
        this._parseError(form, res.errors);
      })
    );
  }

  public parseErrors(formItem: FormGroup | FormArray | FormControl, errors) {
    this._parseError(formItem, errors);
  }

  private _parseError(formItem: FormGroup | FormArray | FormControl, errors) {
    if (formItem instanceof FormGroup) {
      this._parseErrorFormGroup(formItem, errors);
    } else if (formItem instanceof FormArray) {
      this._parseErrorFormArray(formItem, errors);
    } else if (!formItem.errors) {
      this._parseErrorFormControl(formItem, errors);
    }
  }

  private _parseErrorFormGroup(formItem: FormGroup, errors) {
    if (errors.non_field_errors) {
      const _error = errors.non_field_errors[0]? errors.non_field_errors[0]: null;
      formItem.setErrors({
          ajaxValidator: {
              value: _error,
          }
      })
    }
    const _fields = formItem.controls;
    Object.keys(_fields).forEach(key => {
      const _error = errors ? errors[key] ? errors[key] : null : null;
      this._parseError((_fields[key] as FormGroup | FormArray | FormControl), _error);
    });
  }

  private _parseErrorFormArray(formItem: FormArray, errors) {
    formItem.controls.forEach((_item, index) => {
      const _error = errors ? errors[index] ? errors[index] : null : null;
      this._parseError((_item as FormGroup | FormArray | FormControl), _error);
    });
  }

  private _parseErrorFormControl(formItem: FormControl, errors) {
    const _error = errors ? errors[0] ? errors[0] : null : null;
    if (_error) {
      formItem.setErrors({
        ajaxValidator: {
          value: _error,
        }
      });
    } else {
      formItem.setErrors(null);
    }
  }

}
