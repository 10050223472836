import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MenuItems} from '../menu-items/menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {print} from 'util';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChooseProjectService {

    constructor(
        private httpClient: HttpClient,
        private menuItem: MenuItems,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {

    }

    public getProjects(formName=null) {
        const url = environment.api_base_url + '/me/projects';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.get(url, {
                headers: headers
            }
        );
    }

    public chooseProject(project_id) {
        console.log(project_id);
        let current_url = this.router.url;
        let destination_url = "/p/" + project_id;

        console.log('DEBUG - 01');

        if (current_url.match(/p\/[a-z0-9\-]+/) !== null) {
          console.log('DEBUG - 02');
            console.log('vo day');
            destination_url = current_url.replace(/(p)\/([a-z0-9\-]+)/, "$1/" + project_id);
            console.log(destination_url);
        }
        console.log('DEBUG - 03');

        this.router.navigate(
            [destination_url],
        )
    }


}
