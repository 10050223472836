import { Component } from '@angular/core';
import {MultiLanguageService} from "../../../shared/translate/multiLanguageService";

@Component({
  selector: 'app-header-blank',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderBlankComponent {
  public constructor(
      public multiLanguageService: MultiLanguageService,
  ) {}

   onChangeLanguage(language) {
       this.multiLanguageService.changeLanguage(language);
   }
}
