import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable()
export class GlobalVariables {
    currentUrl: string;

    project_id: string = null;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.router.events.pipe(
            filter((event: NavigationEnd) => event instanceof NavigationEnd)
        ).subscribe(value => {
            const parsed_url = value.urlAfterRedirects.match(/p\/([a-z0-9\-]+)/);
            console.log(parsed_url);
            const project_id = (parsed_url !== null) ? parsed_url[1] : null;
            console.log(project_id);
            this.project_id = project_id
        });
    }


    get projectBaseUrl(): string {
        if (this.project_id === null)
            return "/";

        return "/p/" + this.project_id + "/";
    }

}