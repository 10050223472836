import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    auth_token = environment.auth_token;

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    public login(data) {
        const url = environment.api_base_url + '/auth/login';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public register(data) {
        const url = environment.api_base_url + '/auth/register';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public activate(data) {
        const url = environment.api_base_url + '/auth/activate';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );

    }

    public resendEmail(data) {
        const url = environment.api_base_url + '/auth/resend-activation-email';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public logout() {
        const url = environment.accounts_api_base_url + '/auth/logout';
        return this.httpClient.post(url, null).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public forgotPassword(data) {
        const url = environment.api_base_url + '/auth/forgot-password';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public resetPasswordCheckToken(data) {
        const url = environment.api_base_url + '/auth/reset-password-check-token';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public resetPassword(data) {
        const url = environment.api_base_url + '/auth/reset-password';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    public getAuthStatus() {
        const url = environment.accounts_api_base_url + '/auth/auth-status';
        return this.httpClient.get(url
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

}
