import { Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import { CookieService} from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private cookieService: CookieService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const _cookieName = environment.auth_cookie;
        const _tokenName = environment.auth_token;

        if (this.cookieService.check(_cookieName)) {
            const _token = this.cookieService.get(_cookieName);
            request = request.clone({
                withCredentials: true,
                setHeaders: {
                    [_tokenName]: _token,
                }
            });
        }
        return next.handle(request);
    }
}

export let authTokenInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true
};
