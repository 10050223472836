import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {HomeComponent} from './home/home.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'home',
                component: HomeComponent,
                pathMatch: 'full'
            },
            {
                path: 'sysadmin',
                loadChildren: './sysadmin/sysadmin.module#SysadminModule',
            },
            {
                path: 'p',
                children: [
                    {
                        path: ':project_id',
                        children: [
                            {
                                path: '',
                                redirectTo: 'home',
                                pathMatch: 'full'
                            },
                            {
                                path: 'home',
                                component: HomeComponent,
                                pathMatch: 'full'
                            },
                            {
                                path: 'sysadmin',
                                loadChildren: './sysadmin/sysadmin.module#SysadminModule',
                            },
                            {
                                path: 'services',
                                loadChildren: './services/services.module#ServicesModule'
                            }
                        ]
                    }
                ],
            },
        ]
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    './authentication/authentication.module#AuthenticationModule'
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'authentication/404'
    }
];
